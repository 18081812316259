@mixin headerfootercolor {
  background-color: rgba(173, 3, 3, 1);
}
@mixin contactsBannerHeight {
  height: 60vh;
}
@mixin background($url) {
  background: linear-gradient(
      #00000070,
      #00000090,
      #00000090,
      #00000090,
      #00000070
    ),
    url($url);
  background-size: cover;
}
@mixin lighterBackground($url) {
  background: linear-gradient(
      #00000000,
      #00000040,
      #00000060,
      #00000070,
      #00000060,
      #00000040,
      #00000060
    ),
    url($url);
  background-size: cover;
}
