.footer {
  color: white;
  padding: 4% 0 2%;
  bottom: 0;
  p {
    opacity: 1;
  }
  a {
    color: white;
    font-size: 1.8rem;
    font-weight: 600;
  }
  ul {
    justify-content: space-around;
    width: 60%;
    margin: 0 auto 1%;
  }
  @include headerfootercolor;
  @media (max-width: $breakpoint-tablet) {
    width: 100%;
    padding: 0.2% 0;
    display: block;
    position: initial;
    ul {
      display: flex;
      padding: 0;
      width: 100%;
      a {
        border: 2px solid rgba(255, 255, 255, 0.2);
        width: 100%;
        padding: 10% 3%;
        margin: 1% 0;
      }
    }
  }
}
