.fade {
  animation: aniin 1s ease-in;
}

@keyframes aniout {
  from {
    z-index: 5;
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes aniin {
  from {
    z-index: 5;
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.notFound {
  animation: aniout 8s ease-out;
  position: absolute;
  font-size: 4rem;
  color: white;
  text-align: center;
  width: 100vw;
  text-shadow: black 0 0 2px, white 0 0 5px;
}

.opacityBottom {
  opacity: 0;
  transform: translateY(25%);
}
.appearBottom {
  opacity: 1;
  transform: translateY(0);
  transition: 750ms all ease-in;
}

.opacityRight {
  opacity: 0;
  transform: translateX(90%);
  transition: 750ms all ease-in;
}
.appearRight {
  opacity: 1;
  transform: translateX(0);
}
.opacityLeft {
  opacity: 0;
  transform: translateX(-90%);
  transition: 750ms all ease-in;
}
.appearLeft {
  opacity: 1;
  transform: translateX(0);
}

.circleButton {
  transition-duration: 250ms;
  z-index: 6;
  position: fixed;
  bottom: 1%;
  right: 1%;
  border-radius: 50%;
  width: min-content;
  height: min-content;
  top: auto;
}
