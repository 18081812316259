nav {
  li,
  ul {
    display: flex;
    list-style-type: none;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}
.nav {
  position: sticky;
  top: 0;
  opacity: 1;
  z-index: 1;
  @include headerfootercolor;
  display: grid;
  grid-template-columns: 6fr 5fr;
  padding: 0.2% 10%;
  color: white;
  align-items: center;
  nav {
    display: flex;
    justify-content: left;
  }
  ul {
    p {
      font-size: 1.9rem;
      font-weight: 600;
      text-align: right;
      padding: 0 2%;
    }
  }
  a {
    color: white;
    font-weight: 600;
    font-size: 1.6rem;
    margin: 0 1%;
    padding: 0.5% 0;
  }
  @media (max-width: $breakpoint-underhd) {
    nav {
      ul {
        li {
          a {
            padding: 6% 3%;
            width: 100%;
            border: 2px solid rgba(255, 255, 255, 0.2);
          }
        }
      }
    }
  }
  @media (max-width: $breakpoint-phone) {
    width: 100%;
    padding: 0.2% 0;
    display: block;
    position: initial;

    ul {
      p {
        font-size: 1.5rem;
      }
      padding: 0;

      a {
        margin: 1% 0;
        font-size: 1.5rem;
      }
    }
  }
}
