.img0 {
  background: url("../img/IMG_1778.jpg");
  width: 100vw;
  min-width: 100%;
  min-height: 100%;
  background-position-y: 70%;
  filter: contrast(150%) drop-shadow(0px 8px 15px black);
}
.img1 {
  background: url("../img/IMG_0380.jpg");
  @include contactsBannerHeight;
  background-position-x: 25%;
  background-position-y: 35%;
  width: 100vw;
  min-width: 100%;
  min-height: 100%;
  filter: drop-shadow(0px 8px 15px black);
}
.img2 {
  background: url("../img/IMG_0331.jpg");
  @include contactsBannerHeight;
  background-position-x: right;
  background-position-y: 25%;
  width: 100vw;
  min-width: 100%;
  min-height: 100%;
  filter: contrast(125%) drop-shadow(0px 8px 15px black);
}
.homeImg {
  background-size: cover;
  -webkit-background-size: cover; /* safari may need this */
  background-attachment: fixed;
  background-repeat: no-repeat;
  z-index: -1;
  width: 100vw;
  min-width: 100%;
  min-height: 100%;
  margin-bottom: 7%;
}

.pageBanner {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  h1,
  h2 {
    margin: 1% auto;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 9px black;
  }
}

@media (max-width: $breakpoint-tablet) {
  .img0,
  .img1,
  .img2 {
    background-attachment: scroll;
  }
}
.contactBannerSize {
  @include contactsBannerHeight;
  justify-content: space-evenly;
  @media (max-width: $breakpoint-phone) {
    h2 {
      font-size: 2.5rem;
    }
  }
}
.homepageBannerSize {
  height: 60vh;
}
